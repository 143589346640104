import http from '@/utils/services/http'

export const list = (limit, page, filtros) => {
  let url = `/api/funils?page=${page}&limit=${limit}${filtros}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id = null) => {
    let url
    if (id) {
        url = `/api/funils/${id}`
    } else {
        url = `/api/funils/default`
    }
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newFunil = (data) => {
  let url = `/api/funils`
  return http.post(url, data)
      .then(response => {
        return Promise.resolve(response)
      })
      .catch(({response}) => {
        return Promise.reject(response)
      })
}

export const updateFunil = (id, data) => {
    let url = `/api/funils/${id}`
    return http.put(url, data)
        .then(response => {
            return Promise.resolve(response)
        })
        .catch(({response}) => {
            return Promise.reject(response)
        })
}
