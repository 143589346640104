<template>
  <div v-if="loading" class="h-full flex column items-center justify-center">
    <div class="pos-rlt"><sl-loading /></div>
  </div>
  <div v-else class="h-full flex column kanban-module e-input-modern">
    <div class="component-header wrapper w-full flex-zero">
      <e-row>
        <e-col class="flex">
          <u-input hide-underline class="app-input bg-white" style="font-size: 16px; font-weight: bold" v-model="funil.nome"/>
        </e-col>
        <e-col class="flex justify-end">
          <u-btn @click="cancelar" label="Fechar" :loading="loading" no-caps color="white"
                 class="app-btn font-12 no-border-radius-right white m-r-sm"/>
          <u-btn @click="salvar" label="Salvar" :loading="loading" no-caps color="green"
                 class="app-btn font-12 no-border-radius-right"/>
        </e-col>
      </e-row>
    </div>

    <div class="kanban-container edit">
      <div class="kanban-content">
        <div class="kanban-funil-item" @click="activate(etapa)" v-for="(etapa, key) in computedEtapas" :key="key"
             :class="{
          active: etapa.active,
          dropzone: etapa.dropzoneActive,
          dropzoneMoving: etapa.dropzoneMoving
        }"
             @dragstart="dropzone(etapa, true, 'dragstart')"
             @dragenter.prevent.stop="dropzone(etapa, true)"
             @dragover.prevent.stop="dropzone(etapa, true)"
             @drop.prevent.stop="dropzoneDrop($event, etapa, true, 'drop')"
             @dragleave.prevent.stop="dropzone(etapa, false)">
          <div draggable="true" class="funil-header cursor-move">
            <div class="funil-header-content">
              <div class="full-width">
                <u-btn v-if="key > 0" @click.stop.prevent="addLeft(etapa)" class="btn-add btn-add1 bg-white" icon="plus"
                       icon-type="fa" icon-style="light" round size="xs"/>
                <u-btn v-if="key < (funil.etapas.length - 1)" @click.stop.prevent="addRight(etapa)"
                       class="btn-add btn-add2 bg-white" icon="plus" icon-type="fa" icon-style="light" round size="xs"/>
                <div>
                  <div class="etapa-title">{{ etapa.nome }}</div>
                </div>
                <div class="etapa-subtitle">
                  <div class="m-r-sm">
                    <u-icon name="balance-scale-left" type="fa" icon-style="solid" color="grey-7" class="fa-fw"/>
                    {{ etapa.probabilidade }}%
                  </div>
                  <div>
                    <u-icon name="bell" type="fa" color="grey-7" class="fa-fw"/>
                    {{ etapa.estagnadoDiasQtd }} dias
                  </div>
                </div>
              </div>
              <u-icon name="grip-lines-vertical" type="fa" class="m-r cursor-move"/>
              <svg class="arrow" width="16" height="56" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd">
                  <path class="arrow__right" fill="#F7F7F7" d="M0 0h16v56H0z"></path>
                  <path class="arrow__border" fill="#E5E5E5" d="M1 0l8 28-8 28H0V0z"></path>
                  <path class="arrow__left" fill="#F7F7F7" d="M0 1l8 27-8 27z"></path>
                </g>
              </svg>
            </div>
          </div>
          <div class="funil-cards">
            <div class="card">
              <div class="card-content">
                <div>
                  <e-row>
                    <e-col>
                      <erp-s-field label="Nome" class="m-t">
                        <u-input hide-underline class="app-input bg-white" v-model="etapa.nome"/>
                      </erp-s-field>
                    </e-col>
                  </e-row>
                  <e-row>
                    <e-col>
                      <div class="erp-s-field m-t-xs erp-field-align-left erp-field-layout-top">
                        <div class="erp-s-field-label">Probabilidade
                          <u-icon name="question-circle" type="fa" icon-style="light"/>
                        </div>
                        <div class="erp-s-field-content">
                          <u-input hide-underline class="app-input bg-white" v-model="etapa.probabilidade"/>
                        </div>
                      </div>
                    </e-col>
                  </e-row>
                  <e-row>
                    <e-col>
                      <div class="erp-s-field m-t-xs erp-field-align-left erp-field-layout-top">
                        <div class="erp-s-field-label">
                          <div class="full-width flex justify-between">
                            <div>
                              Estagnado em (dias)
                              <u-icon name="question-circle" type="fa" icon-style="light"/>
                            </div>
                            <div>
                              <label data-test="stage-1-rotting-flag" class="check-switch">
                                <input type="checkbox" v-model="etapa.estagnadoDias">
                                <div class="check-switch-toggle"></div>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div v-show="etapa.estagnadoDias" class="erp-s-field-content">
                          <u-input hide-underline class="app-input bg-white" v-model="etapa.estagnadoDiasQtd"/>
                        </div>
                      </div>
                    </e-col>
                  </e-row>
                </div>
                <div class="footer">
                  <u-btn @click="excluirEtapa(etapa)" label="Excluir etapa" icon="trash" icon-type="fa" no-caps class="full-width"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {ErpSField} from "uloc-vue-plugin-erp"
import {UInput} from "uloc-vue"
import {newFunil, find, updateFunil} from "@/domain/negocio/services/funil"
import SlLoading from "components/layout/components/Loading"

const geraEtapa = function (nome, order) {
  return JSON.parse(JSON.stringify({
    nome: nome,
    probabilidade: 100,
    estagnadoDias: false,
    estagnadoDiasQtd: 0,
    active: true,
    order: order,
    dropzoneActive: false,
    dropzoneMoving: false
  }))
}

export default {
  name: "KanbanFunilEdit",
  data() {
    return {
      loading: true,
      dropzoneActive: false,
      funil: {
        id: null,
        nome: 'Produtividade',
        etapas: [
          geraEtapa('Qualificado', 1),
          geraEtapa('Contato Realizado', 2),
          geraEtapa('Demonstração Agendada', 3),
          geraEtapa('Proposta Feita', 4),
          geraEtapa('Negociações Iniciadas', 5)
        ]
      },
    }
  },
  components: {
    SlLoading,
    ERow,
    ECol,
    UInput,
    ErpSField,
    //ErpInput
  },
  computed: {
    computedEtapas() {
      return this.funil.etapas.slice().sort((a, b) => {
        return a.order - b.order
      })
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.load(this.$route.params.id)
    } else {
      this.loading = false
    }
  },
  methods: {
    load(id) {
      this.loading = true
      id = typeof id === 'undefined' ? null : id
      find(id)
        .then(response => {
          if (id) {
            this.funil = response.data
          } else {
            this.funis = response.data
            this.funil = this.active()
          }
          this.$nextTick(() => {
            this.loading = false

            this.loadNegocios(this.funil)
          })
        })
        .catch(error => {
          this.loading = false
          this.alertApiError(error)
        })
    },
    activate(etapa) {
      if (!this.funil.etapas.length) return
      this.funil.etapas.map(e => {
        e.active = false
      })
      console.log('Activate etapa ', etapa)
      this.$nextTick(() => {
        etapa.active = true
      })
    },
    addLeft(etapa) {
      console.log('Add Left', etapa)
      this.funil.etapas.push(geraEtapa('Nova Etapa', etapa.order - 1))
    },
    addRight(etapa) {
      console.log('Add Right', etapa)
      this.funil.etapas.push(geraEtapa('Nova Etapa', etapa.order))
    },
    excluirEtapa (etapa) {
      this.$uloc.dialog({
        title: 'Excluir etapa',
        message: 'Você tem certeza que deseja excluir esta etapa?',
        ok: 'Sim',
        cancel: 'Não',
        color: 'negative'
      }).then(() => {
        this.funil.etapas.splice(this.funil.etapas.indexOf(etapa), 1)
      }).catch(() => {})
    },
    dropzone(e, active, event = null) {
      this.dropzoneActive = active
      e.dropzoneActive = active
      if (event === 'dragstart') {
        e.dropzoneMoving = true
        this.dropactiveElement = e
      }
    },
    dropzoneDrop(event, e, active) {
      console.log(event, e)
      this.dropzoneActive = active
      // @TODO: Ajustar forma de ordenação, isso é temporário
      if (e.order > this.dropactiveElement.order) {
        this.dropactiveElement.order = e.order + 1
      } else {
        this.dropactiveElement.order = e.order - 1
        e.order = e.order - 1
      }
      this.$nextTick(() => {
        let i = 0
        this.computedEtapas.map(_e => {
          i++
          _e.order = i
        })
      })
      e.dropzoneActive = active
      e.dropzoneMoving = false
      this.dropactiveElement.dropzoneMoving = false
      delete this.dropactiveElement

    },
    cancelar () {
      this.$router.push({name: 'negocio.kanban'})
    },
    salvar() {
      const funil = JSON.parse(JSON.stringify(this.funil))
      funil.etapas = JSON.parse(JSON.stringify(this.computedEtapas))
      let method = newFunil
      if (funil.id) {
        method = (data) => updateFunil(funil.id, data)
      }
      this.loading = true
      method(funil)
          .then(response => {
            this.loading = false
            this.$uloc.notify({
              color: 'black',
              message: !funil.id ? `Novo Funil "${response.data.nome}" criado.` : 'Funil Atualizado',
              position: 'bottom-left',
            })
            !funil.id && this.$router.push({
              name: 'negocio.kanban',
              query: {
                funil: response.data.id
              }
            })
          })
          .catch(error => {
            this.loading = false
            this.alertApiError(error)
          })
    }
  },
  meta: {
    title: 'Criar Funil'
  }
}
</script>
